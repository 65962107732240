<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.typeId"
        placeholder="设备类别"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in typeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">设备报修标签数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="标签名称"
        align="center"
        prop="tag"
      ></el-table-column>
      <el-table-column label="类别" width="100" align="center" prop="type">
        <template slot-scope="scope">
          {{ scope.row.device_type && scope.row.device_type.name }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
        width="200px"
      >
        <template slot-scope="scope">
          <el-popconfirm
            confirm-button-text="是的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定删除该标签吗？"
            @confirm="deleteTagFun(scope.row)"
            style="margin: 0 8px;"
          >
            <el-button slot="reference" type="danger" size="mini"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="标签名称" prop="name">
          <el-input v-model="form.tag" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="设备类别" prop="type">
          <el-select
            v-model="form.typeId"
            placeholder="请选择类别"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in typeOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { DIALOG_TITLE_TYPE } from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import {
  createReportrepairTag,
  deleteReportrepairTag,
  getEquipmentList,
  getReportrepairTagList
} from "@/api/equipment";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        typeId: ""
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogStatus: "CREATE",
      rules: {
        typeId: [
          { required: true, message: "设备类别不能为空", trigger: "change" }
        ],
        tag: [
          { required: true, message: "标签名称不能为空", trigger: "change" }
        ]
      },
      form: {
        id: "",
        typeId: "",
        tag: ""
      },
      btnLoading: false,
      editObj: {}, // 修改的对象
      settingObj: {}, // 设置的对象
      typeOption: [] // 类型选项
    };
  },
  created() {
    this.getList();
    this.getTypeOption();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备类型
     */
    getTypeOption() {
      const params = {
        page: 1,
        limit: 9999
      };
      getEquipmentList(params).then(resp => {
        this.typeOption = resp.data.data;
      });
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getReportrepairTagList(params)
        .then(response => {
          console.log("response", response);
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;

      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.typeId = editObj.type_id;
        this.form.tag = editObj.tag;
      } else {
        this.form.typeId = "";
        this.form.tag = "";
        this.$refs.form.resetFields();
      }
    },

    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const params = this.form;
          this.btnLoading = true;
          createReportrepairTag(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },

    /**
     * 删除
     * @param row
     */
    deleteTagFun(row) {
      if (!row || Object.keys(row).length === 0) {
        return;
      }
      const params = {
        id: row.id
      };
      deleteReportrepairTag(params).then(resp => {
        if (resp.code === 0) {
          this.getList();
          this.$notify({
            title: "成功",
            message: resp.data,
            type: "success",
            duration: 2000
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
